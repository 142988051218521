<template>
  <page-layout :ready="!loading" class="bg-white px-1">
    <template slot="breadcrumb">
      <el-breadcrumb-item>
        <h1 class="text-typography-primary">
          {{ $t('operations.overview.header') }}
        </h1>
      </el-breadcrumb-item>
    </template>
    <div class="mb-5"></div>
    <DomainTaskStatus category="document" class="mb-7" :task-data="getTaskDataByDomain('document')">
      <DocumentsIcon width="32" height="32" />
    </DomainTaskStatus>
    <DomainTaskStatus class="mb-7" category="priceListsAndAgreements" :task-data="[]">
      <TermsAndAgreementsIcon width="32" height="32" style="margin-right: -2px" />
    </DomainTaskStatus>
    <DomainTaskStatus class="mb-7" category="reconciliation" :task-data="[]">
      <AccountingIcon width="32" height="32" />
    </DomainTaskStatus>
    <DomainTaskStatus class="mb-7" category="payment" :task-data="getTaskDataByDomain('payment')">
      <PaymentsIcon width="32" height="32" class="m-n2" />
    </DomainTaskStatus>
    <DomainTaskStatus class="mb-7" category="product" :task-data="getTaskDataByDomain('product')">
      <ProductsIcon width="32" height="32" />
    </DomainTaskStatus>
    <DomainTaskStatus class="mb-7" category="delivery" :task-data="getTaskDataByDomain('delivery')">
      <ReceiptsAndReturnsIcon width="32" height="32" />
    </DomainTaskStatus>
  </page-layout>
</template>

<script>
import { ref } from 'vue';

import {
  DocumentsIcon,
  TermsAndAgreementsIcon,
  AccountingIcon,
  PaymentsIcon,
  ProductsIcon,
  ReceiptsAndReturnsIcon,
} from '@/assets/icons';
import { useTasksAggregation } from '@/modules/tasks';

import DomainTaskStatus from './components/DomainTaskStatus';
import { getTaskType } from './compositions/tasks';

const taskDataByTypeResolver = (acc, currAggregation) => {
  const taskType = getTaskType(currAggregation);
  if (!taskType) return acc;

  if (!acc[taskType]) {
    acc[taskType] = {
      standardTimeTasksCount: 0,
      inadequateTimeTasksCount: 0,
      lateTimeTasksCount: 0,
      domain: currAggregation.domain,
      type: taskType,
    };
  }

  if (!currAggregation.daysOpen) acc[taskType].standardTimeTasksCount += currAggregation.count;
  else if (currAggregation.daysOpen === 1) acc[taskType].inadequateTimeTasksCount += currAggregation.count;
  else acc[taskType].lateTimeTasksCount += currAggregation.count;
  return acc;
};

export default {
  components: {
    DomainTaskStatus,
    DocumentsIcon,
    ReceiptsAndReturnsIcon,
    TermsAndAgreementsIcon,
    AccountingIcon,
    PaymentsIcon,
    ProductsIcon,
  },
  setup() {
    const { aggregations, loading, refetch } = useTasksAggregation(
      ref({ filters: { completed: false }, options: { groupBy: ['businessId', 'domain', 'type', 'daysOpen'] } })
    );

    const refetchInterval = setInterval(refetch, 30000);

    return {
      refetchInterval,
      aggregations,
      loading,
    };
  },
  computed: {
    taskData() {
      const taskDataByType = this.aggregations.reduce(taskDataByTypeResolver, {});
      return Object.entries(taskDataByType).map(([type, data]) => ({
        ...data,
        taskType: type,
        title: this.$t(`operations.overview.categories.${data.domain}.types.${type}`),
      }));
    },
  },
  destroyed() {
    if (this.refetchInterval) {
      clearInterval(this.refetchInterval);
      this.refetchInterval = null;
    }
  },
  methods: {
    getTaskDataByDomain(domain) {
      return this.taskData.filter((taskData) => taskData.domain === domain);
    },
  },
};
</script>
